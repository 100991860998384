@use "mixins";
@use "variables" as variables;
@use "placeholders" as *;

html {
  body {
    font-family: 'Poppins', sans-serif;
    font-variant: normal;
    margin: 0;
    color: black;
    font-size: 16px;
    line-height: normal;
    box-sizing: border-box;
    min-height: 100vh;

    @include mixins.scroll-bar();

    * {
      box-sizing: border-box;
      @include mixins.scroll-bar();
      //scroll-behavior: smooth;
      //scroll-behavior: auto;
      //touch-action: manipulation; // disables double tap zoom
    }

    *,
    *:before,
    *:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    @include mixins.scroll-bar();

    a {
      color: initial;

      &:hover {
        color: initial;
      }
    }

    h1, h2, h3, h4, h5, h6 {
      margin: 0;
    }

    @for $i from 0 through 100 {
      @if $i % 2 == 0 {
        .m-#{$i} {
          margin: #{$i}px !important;

          &-minus {
            margin: -#{$i}px !important;
          }
        }

        .m-tb-#{$i} {
          margin-top: #{$i}px !important;
          margin-bottom: #{$i}px !important;

          &-minus {
            margin-top: -#{$i}px !important;
            margin-bottom: -#{$i}px !important;
          }
        }

        .m-lr-#{$i} {
          margin-left: #{$i}px !important;
          margin-right: #{$i}px !important;

          &-minus {
            margin-left: -#{$i}px !important;
            margin-right: -#{$i}px !important;
          }
        }

        .mt-#{$i} {
          margin-top: #{$i}px !important;

          &-minus {
            margin-top: -#{$i}px !important;
          }
        }

        .mb-#{$i} {
          margin-bottom: #{$i}px !important;

          &-minus {
            margin-bottom: -#{$i}px !important;
          }
        }

        .mr-#{$i} {
          margin-right: #{$i}px !important;

          &-minus {
            margin-right: -#{$i}px !important;
          }
        }

        .ml-#{$i} {
          margin-left: #{$i}px !important;

          &-minus {
            margin-left: -#{$i}px !important;
          }
        }

        .p-#{$i} {
          padding: #{$i}px !important;
        }

        .p-tb-#{$i} {
          padding-top: #{$i}px !important;
          padding-bottom: #{$i}px !important;
        }
        .p-lr-#{$i} {
          padding-left: #{$i}px !important;
          padding-right: #{$i}px !important;
        }

        .pt-#{$i} {
          padding-top: #{$i}px !important;
        }
        .pb-#{$i} {
          padding-bottom: #{$i}px !important;
        }
        .pr-#{$i} {
          padding-right: #{$i}px !important;
        }
        .pl-#{$i} {
          padding-left: #{$i}px !important;
        }
      }
    }

    @for $i from 0 through 50 {
      .fs-#{$i} {
        font-size: #{$i}px !important;
      }
    }

    @for $i from 0 through 500 {
      @if $i % 2 == 0 {

        .w-#{$i} {
          width: #{$i}px !important;
        }
        .h-#{$i} {
          height: #{$i}px !important;
        }

        .max-w-#{$i} {
          max-width: #{$i}px !important;
        }
        .max-h-#{$i} {
          max-height: #{$i}px !important;
        }

        .min-w-#{$i} {
          min-width: #{$i}px !important;
        }
        .min-h-#{$i} {
          min-height: #{$i}px !important;
        }

      }
    }

    $opacity-values: 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8;

    @each $opacity in $opacity-values {
      $opacity-int: round($opacity * 100); // Multiply by 100 and round to get an integer
      .opacity-#{$opacity-int} {
        opacity: $opacity;
      }
    }

    /* Ready Class Names Start */

    .l-height-initial {
      line-height: initial;
    }

    .d-none {
      display: none;
    }

    .d-flex {
      display: flex;

      /* Alight */

      &.ai-c {
        align-items: center;
      }

      &.align-s {
        align-items: flex-start;
      }

      &.align-e {
        align-items: flex-end;
      }

      &.align-c {
        align-items: center;
      }

      &.align-b {
        align-items: baseline;
      }

      &.align-str {
        align-items: stretch;
      }

      /* Justify */

      &.jc-sb {
        justify-content: space-between;
      }

      .jc-se {
        justify-content: space-evenly;
      }

      &.jc-fs {
        justify-content: flex-start;
      }

      &.jc-fe {
        justify-content: flex-end;
      }

      &.jc-c {
        justify-content: center;
      }

      &.jc-sa {
        justify-content: space-around;
      }

      &.jc-se {
        justify-content: space-evenly;
      }

      &.f-center {
        align-items: center;
        justify-content: center;
      }

      &.f-wrap {
        flex-wrap: wrap;
      }

      /* Direction */

      &.fd-rr {
        flex-direction: row-reverse;
      }

      &.fd-c {
        flex-direction: column;
      }

      @for $i from 0 through 100 {
        @if $i % 2 == 0 {
          &.gap-#{$i} {
            gap: #{$i}px !important;
          }
          &.gap-x-#{$i} {
            gap: #{$i}px !important;
          }
          &.gap-y-#{$i} {
            gap: #{$i}px !important;
          }
        }
      }

    }

    .disabled {
      pointer-events: none;
      opacity: .5;
    }

    .sf-error {
      color: #E25B5C;
    }

    .sf-success {
      color: #16DEAE;
    }

    .sf-warning {
      color: #EF9E23;
    }

    .sf-info {
      color: #4FB2DA;
    }

    .txt-center {
      text-align: center;
    }

    /* Ready Class Names End */


    i[nz-icon] {
      font-size: 16px;
    }

    input {
      &:focus {
        outline: none;
      }
    }

    i[class^='ph-'], i[class*='ph-'] {
      font-size: 16px;
    }

    i[class^='ri-'], i[class*='ri-'] {
      font-size: 16px;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }

    input[readonly] {
      pointer-events: none;

      &:focus {
        border: 1px solid #B3B3B3;
      }
    }

    textarea[readonly] {
      pointer-events: none;

      &:focus {
        border: 1px solid #B3B3B3;
      }
    }

    .sf-link {
      color: #00A3FF;
      font-size: 16px;
      cursor: pointer;
      transition: .3ms;

      &:hover {
        text-decoration: underline;
        color: #00A3FF;
      }
    }

    .sf-policy {
      margin: 10px 0px 10px 0px;
    }

    .sf-policy-list {
      margin: 10px 0px 10px 25px;
      list-style-type: disc;
    }

    .sf-policy-text-justify {
      text-align: justify;
    }

    .sf-link2 {
      color: #4a57dc;
      font-size: 16px;
      cursor: pointer;
      transition: .3ms;

      &:hover {
        text-decoration: underline;
        color: #4a57dc;
      }
    }

    .br-none {
      border-radius: 0;
    }

    .ellipsis {
      @extend %ellipsis;
    }

    /**************** NZ Style Configs Start  ****************/

    .ai-skills-c, .ai-summary-c {
      .ant-dropdown-menu-root {
        .ant-dropdown-menu-item {
          cursor: initial;

          &:hover {
            background-color: initial;
          }
        }
      }
    }

    .work-exp-ai-dropdown {
      .ant-dropdown-menu-root {
        .ant-dropdown-menu-item {

          .ant-menu-title-content {
            width: 100%;
            display: flex;
            justify-content: center;
          }

          background-color: white;

          &:hover {
            //background-color: initial;
          }

          .ant-dropdown-menu {
            .ant-dropdown-menu-submenu-title {
              font-size: 12px;
            }

            svg {
              display: none;
            }
          }
        }
      }
    }

    nz-modal-confirm-container {
      &.upload-cv-check-modal {
        .ant-modal-body {
          padding-bottom: 10px !important;

          .ant-modal-confirm-content {
            margin: 0 !important;
          }
        }
      }
    }

    nz-upload {
      &.upload-cv-file {
        width: 100%;

        .ant-upload-select {
          width: 100%;
        }
      }
    }

    .ant-dropdown-menu {
      &.header-user-menu {
        border-radius: 8px;
        padding: 6px 10px;
        min-width: 196px;

        .user-menu-item {
          min-height: 40px;
          border-radius: 4px;
          padding: 0;

          > span {
            width: 100%;
          }

          a {
            color: black;
            @extend %flexCenter;
            padding: 5px 12px;
            @include mixins.square(100%);

            > i {
              margin-right: 8px;
            }
          }

          /*.profile-info-menu-item {
            padding: 10px 2px 10px 14px;

            .u-full-name {
              margin-bottom: 4px;
            }

            .u-email, .u-full-name {
              font-size: 13px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }*/

        }
      }

      &.template-settings-menu {
        border-radius: 8px;
        padding: 6px 10px;

        > li {
          border-radius: 4px;

          &.red {
            color: variables.$red;
          }

          > i {
            margin-right: 8px;
          }
        }
      }
    }

    .ant-picker-focused {
      box-shadow: none;
      border-color: #16DEAE !important;
    }

    .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
      border-color: #16DEAE;
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: #16DEAE;
      box-shadow: none;
    }

    .cdk-overlay-container {
      nz-option-container {
        width: 320px;
      }
    }

    .ant-modal-content {
      border-radius: 8px;

      .ant-modal-header {
        border-radius: 4px;
      }
    }

    .ant-table {
      .ant-table-container table > thead > tr:first-child th:first-child {
        border-radius: 8px 0 0 8px;
      }

      .ant-table-container table > thead > tr:first-child th:last-child {
        border-radius: 0 8px 8px 0;
      }
    }

    .ant-drawer-header {
      padding: 16px;
    }

    .paid-banner-drawer-title {
      .ant-drawer-header {
        padding: 0;
      }
    }

    .ant-drawer-content-wrapper {
      &.payment-drawer {
        @media only screen and (max-width: variables.$mediumScreen) {
          width: 378px !important;
        }
      }
    }

    .nz-payment-sidebar {
      @media only screen and (max-width: variables.$mediumScreen) {
        width: 378px !important;
      }
    }

    .dashboard-spin {
      .ant-spin.ant-spin-spinning {
        position: fixed;
        top: calc(50% - 200px);
      }
    }

    nz-divider {
      &.tm-list-preview {
        height: 40px;
        top: 0;
      }
    }

    nz-date-picker {
      &.date-picker-fs-12 {
        .ant-picker-input {
          > input {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      &.date-picker-fs-15 {
        .ant-picker-input {
          > input {
            font-size: 15px;
            line-height: 22.5px;
          }
        }
      }
    }

    .tm-skeleton {
      .ant-skeleton-with-avatar {
        .ant-skeleton-header {
          .ant-skeleton-element {
            .ant-skeleton-avatar {
              @include mixins.square(132px);
            }
          }
        }
      }
    }

    nz-collapse {
      &.FAQ-nz-collapse {
        border: none;

        .ant-collapse-item {
          &.ant-collapse-item-active {
            border: none;

            .ant-collapse-content {
              border: none;
            }

            .ant-collapse-header {
              .ant-collapse-arrow {
                transform: translateY(-50%) rotate(180deg);
              }
            }
          }

          .ant-collapse-header {
            padding: 14px 38px 14px 18px;

            .ant-collapse-arrow {
              transform: translateY(-50%) rotate(90deg);
            }
          }

          &:last-child {
            border: none;
          }
        }


        /*.ant-collapse-item {
          border: none;

          .ant-collapse-content {
            border: none;
          }
        }*/
      }
    }

    @media only screen and (max-width: 400px) {
      .ant-drawer {
        .ant-drawer-content-wrapper {
          width: 100% !important;
        }
      }
    }

    /**************** NZ Style Configs End  ****************/

    /**************** PRIME Style Configs Start  ****************/

    p-carousel {
      .p-carousel-content {
        .p-carousel-container {
          margin-bottom: 20px;
        }
      }

      .p-carousel-prev {
        margin-left: 2px;
      }

      .p-carousel-next {
        margin-right: 2px;
      }

      .p-carousel-next, .p-carousel-prev {
        background: none;
        border: none;
        cursor: pointer;

        border-radius: 4px;
        width: 27px;
        height: 28px;


        &.p-disabled {
          pointer-events: none;
          opacity: .5;
        }

        &:hover {
          background-color: rgba(243, 244, 255, .5);
          backdrop-filter: blur(10px);
        }
      }

      .p-carousel-indicators {
        > li {
          margin: 0 2px;

          > button {
            width: 4px;
            height: 4px;
            cursor: pointer;
            border-radius: 2px;
            background-color: #d1d5db;
            border: none;
          }

          &.p-highlight {
            > button {
              background-color: #4B57DC;
            }

            &:hover {
              > button {
                background-color: #4B57DC;
              }
            }
          }

          &:hover {
            > button {
              background-color: #9ca3af;
            }
          }
        }
      }
    }

    /**************** PRIME Style Configs End  ****************/

    /**************** QUILL.JS Style Configs End  ****************/

    .quill-page-container {

      &.article-item {
        // Edit Mode Start

        max-width: 800px;
        //margin: 40px auto 40px;
        //border: 1px solid #e2e8f0;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        color: #334155;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1019607843), 0 1px 2px -1px rgba(0, 0, 0, 0.1019607843);

        .ql-toolbar {
          display: none;
        }

        #selfcv-editor {
          border: none;

          .ql-editor {
            padding: 46px 24px 100px 24px;
          }
        }

        h1 {
          font-size: 36px;
          font-weight: 700;
          line-height: initial;
          margin-bottom: 4px;
        }

        // Edit Mode End
      }

      font-size: 16px;

      .ql-font-poppins {
        font-family: 'Poppins', sans-serif;
      }

      .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="poppins"]::before,
      .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="poppins"]::before {
        content: 'Poppins';
        font-family: 'Poppins', sans-serif;
      }

      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
        content: '10px';
        font-size: 10px;
      }

      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
        content: '12px';
        font-size: 12px;
      }

      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
        content: '14px';
        font-size: 14px;
      }

      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
        content: '16px';
        font-size: 16px;
      }

      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
        content: '18px';
        font-size: 18px;
      }

      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
        content: '20px';
        font-size: 20px;
      }

      .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
        content: '22px';
        font-size: 22px;
      }

      .ql-toolbar.ql-snow {
        position: sticky;
        top: 80px;
        z-index: 9999;
        background-color: white;

        > span {
          margin: 4px;
          border: 1px solid lightgray;
          border-radius: 4px;
          padding: 2px;
        }

      }

      .ql-editor {
        background-color: white;
        min-height: 300px;
        resize: vertical;

        h2 {
          font-weight: 700;
          font-size: 24px;
          color: 334155FF;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 18.72px;
          font-weight: 700;
          color: 334155FF;
          margin-bottom: 8px;
        }

        p {
          //margin: 16px 0;
        }

        ol {
          margin: 8px;

          li {
            padding: 4px 0;
          }
        }

        .ql-divider {
          border: none;
          height: 1px;
          background: #ddd;
          margin: 16px 0;
        }

        .ql-panel {
          border: 1px solid #d9d9d9;
          padding: 10px;
          border-radius: 5px;
          background-color: #f9f9f9;
        }

        //div:contains("selfcv_baner_here") {
        //  color: red;
        //}

        .ql-code-block-container {
          background-color: white;
          color: black;
          overflow: visible;
          border: 1px solid lightgray;
          font-family: 'Poppins', sans-serif;
        }

        .selfcv-banner {
          color: red;
        }

        img {
          width: 80%;
          margin: 40px auto;
          display: block;
          border-radius: 4px;
          border: 1px solid lightgray;
        }

        a[href] {
          color: #00A3FF;
          font-size: 16px;
          cursor: pointer;
          transition: .3ms;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
            color: #00A3FF;
          }
        }


      }

    }

    /**************** QUILL.JS Style Configs End  ****************/


    .sf-sidebar-title {
      @extend %flexCenterBetween;

      > i {
        font-size: 20px;
        cursor: pointer;
      }
    }

    .dashboard-sidebar-title {
      @extend %flexCenterBetween;

      > i {
        cursor: pointer;
      }
    }

    .sf-lng-item {
      @extend %flexCenter;

      > img {
        margin-right: 8px;
        @include mixins.square(24px);
      }
    }

    /* Dropdown wrapper classes start */

    .lng-dropdown {
      .dropdown-container {
        .selected-item {
          padding: 0 10px;
        }

        .dropdown-items-list {
          padding: 10px;

          .items-container {
            .all-dropdown-items {
              max-height: 310px;

              .dropdown-item-el {
                margin-right: 0;
              }
            }
          }

        }
      }
    }

    .payment-countries-dr {
      &.sw-dropdown {
        .dropdown-container {
          .selected-item {
            .inner-text {
              font-size: 16px;
            }
          }

          .dropdown-items-list {
            .all-dropdown-items {
              .dropdown-item-el {
                font-size: 16px;
              }
            }
          }

          &:hover {
            .selected-item {
              border: 1px solid #16DEAE;
            }

            .dropdown-items-list {
              border-right: 1px solid #16DEAE;
              border-bottom: 1px solid #16DEAE;
              border-left: 1px solid #16DEAE;
            }
          }
        }
      }
    }

    .small-paddings {
      .dropdown-items-list {
        padding: 10px 10px !important;
      }
    }

    .selected-item-full-length {
      .dropdown-container {
        .selected-item {
          > span {
            width: 100%;
          }
        }
      }
    }

    /* Dropdown wrapper classes end */

    .custom-intojs-tooltip {
      font-family: 'Poppins', sans-serif;
      //font-size: 14px;

      .introjs-tooltip-header {
        font-family: 'Poppins', sans-serif;
      }

      .introjs-tooltiptext {
        font-family: 'Poppins', sans-serif;
      }

      .introjs-bullets {
        padding-top: 6px;
      }

      .introjs-tooltipbuttons {
        .introjs-button {
          font-family: 'Poppins', sans-serif;
          background-color: #4B57DC;
          border: 1px solid #4B57DC;
          color: #FFFFFF;
          box-shadow: none;
          text-shadow: none;
        }
      }
    }

    .top-banners-wrapper {
      animation: scale 0.2s, opacity 0.5s;
    }

    .tm-social-st {
      width: 120px;

      > li {
        justify-content: space-between;
        padding: 4px 6px 4px 10px;

        /*> i {
          background-color: white;
          padding: 4px 4px;
        }*/
      }
    }

    .p {
      &-absolute {
        position: absolute;
      }

      &-relative {
        position: relative;
      }
    }

    .c {
      &-pointer {
        cursor: pointer;
      }

      &-initial {
        cursor: initial;
      }
    }

    .color {
      &-4b57dc {
        color: #4b57dc
      }

      &-black {
        color: black;
      }
    }

    .fw {

      &-100 {
        font-weight: 100;
      }

      &-200 {
        font-weight: 200;
      }

      &-300 {
        font-weight: 300;
      }

      &-400 {
        font-weight: 400;
      }

      &-500 {
        font-weight: 500;
      }

      &-600 {
        font-weight: 600;
      }

      &-700 {
        font-weight: 700;
      }

      &-800 {
        font-weight: 800;
      }

      &-900 {
        font-weight: 900;
      }

      &-bold {
        font-weight: bold;
      }

      &-bolder {
        font-weight: bolder;
      }

      &-lighter {
        font-weight: lighter;
      }
    }

    .w-full {
      width: 100% !important;
    }

    .w-fit {
      width: fit-content !important;
    }

    .h-full {
      height: 100% !important;
    }

    .w-half {
      width: 50% !important;
    }

    &.builder-page {
      @media only screen and (max-width: 650px) {
        main {
          overflow-x: auto;
          width: 650px;
        }
      }
    }

    .template-preview-cl {
      .ant-modal {
        top: 38px;

        .ant-modal-content {
          .ant-modal-close {
            > span {
              @include mixins.square(41px);
              line-height: 41px;
            }
          }

          .ant-modal-header {
            padding: 4px;
          }

          .ant-modal-body {
            padding: 0;

            sf-cover-letter,
            sf-cv-template {
              position: absolute;
              pointer-events: none;
              transform: scale(0.63);
              transform-origin: 0 10px;
              padding-bottom: 38px;

              .template-main-container {
                .pages-space {
                  //height: 30px;
                }

                .empty-section {
                  display: none;
                }
              }

            }
          }
        }
      }

      @media only screen and (max-width: 650px) {
        div.ant-modal {
          width: 358px !important;

          .ant-modal-content {
            .ant-modal-body {
              sf-cover-letter,
              sf-cv-template {
                transform: scale(0.376);
              }
            }
          }
        }
      }

      @media only screen and (max-width: 400px) {
        div.ant-modal {
          width: 333px !important;

          .ant-modal-content {
            .ant-modal-body {
              sf-cover-letter,
              sf-cv-template {
                transform: scale(0.350);
              }
            }
          }
        }
      }

    }

    .template-preview-cl2 {
      .ant-modal {
        top: 38px;

        .ant-modal-content {
          background: none;
          box-shadow: none;

          .ant-modal-close {
            left: 0;
            right: initial;

            > span {
              height: 48px;
              width: 46px;
              line-height: 48px;
            }
          }

          .ant-modal-header {
            padding: 4px;
            border: none;
          }

          .ant-modal-body {
            padding: 0;

            .tm-images-list {
              margin-top: 10px;

              img {
                width: 100%;
                border-radius: 4px;

                &:first-child {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }

      &.img-has-borders {
        .ant-modal {
          .ant-modal-content {
            .ant-modal-close {
              left: 6px;
            }

            .ant-modal-header {
              margin-right: 8px;
              margin-left: 8px;
            }

            .ant-modal-body {
              .tm-images-list {
                margin-top: 0;

                img {
                  &:first-child {
                    margin-bottom: -14px;
                  }
                }
              }
            }
          }
        }
      }

      @media only screen and (max-width: 900px) {
        .ant-modal {
          width: 600px !important;
        }
      }

      @media only screen and (max-width: 650px) {
        div.ant-modal {
          width: 358px !important;

          .ant-modal-content {
            .ant-modal-body {

              .tm-images-list {
                margin-top: 4px;

                img {
                  &:first-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }

        &.img-has-borders {
          .ant-modal {
            .ant-modal-content {
              .ant-modal-header {
                margin-right: 4px;
                margin-left: 4px;
              }

              .ant-modal-body {

                .tm-images-list {

                  img {
                    &:first-child {
                      margin-bottom: -10px;
                    }
                  }
                }
              }
            }
          }
        }

      }
    }

    .premium-plans {
      &.inside-nz-drawer {
        min-width: initial;
        padding: 0;
        border: none;

        .plans {
          .plan-item {
            .pl-content {
              .get-started {
                button {
                  width: 110px !important;
                }
              }
            }
          }
        }
      }
    }

    .tm-color {
      .tm-colors-list {
        @extend %flexCenter;
        flex-wrap: wrap;
        gap: 8px;

        .tm-color-item {
          @include mixins.square(36px);
          border-radius: 8px;
          overflow: hidden;
          @extend %flexCenter;
          cursor: pointer;
          position: relative;

          > div {
            width: 100%;
            height: 100%;

            &:nth-child(1) {
              border-radius: 8px 0 0 8px;
            }

            &:nth-child(2) {
              border-radius: 0 8px 8px 0;
            }
          }

          > i {
            position: absolute;
            right: 6px;
            bottom: 4px;
            color: white;
            font-size: 8px;
          }

          &.active, &:hover {
            padding: 2px;
            border: 1px solid #4B57DC;

            > i {
              bottom: 5px;
            }
          }

        }
      }

      &.on-tm-preview {
        .tm-colors-list {
          gap: 6px;
        }
      }
    }

    .template-types-container {
      width: 116px;

      > h3 {
        margin-bottom: 20px;
      }

      .template-types {
        @extend %flexBetween;

        img {
          border: 1px solid lightgray;
          border-radius: 4px;
          cursor: pointer;

          &.active {
            border: 1px solid #4B57DC;
          }
        }
      }
    }

    .template-prev-title {
      > button {
        width: 198px;
        padding-left: 14px;
        justify-content: space-evenly;
      }

      @media only screen and (max-width: 650px) {
        > button {
          width: 40px;
          padding-left: 8px;

          > span {
            display: none;
          }
        }
      }
    }

    /* Dragging Start */


    /* Dragging End */

    &.lang_fr, &.lang_de {
      .paid-plan-info {
        .pr-plan {
          width: initial !important;
          margin-right: 6px !important;
        }

        @media only screen and (max-width: 576px) {
          .pl-date-wr {
            width: initial !important;
          }
        }
      }
    }

    //animation: tickle .25s ease 0ms infinite alternate forwards;

    @keyframes tickle {
      0% {
        transform: none;
      }
      50% {
        transform: scale(1.1) rotate(-12deg) translateY(-2px);
      }
      100% {
        transform: scale(1.2) rotate(12deg) translateY(-4px);
      }
    }

  }
}


